import Moment from "moment";

export default {
  name: "THistoryProfitShare",
  data() {
    return {
      property: {
        animation: {
          editHistoryProfitShare: {
            isLoading: false,
          },
        },
        listElement: {
          HistoryProfitShare: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          periodeDate: "",
          isTransfered: [],
          statusId: "",
          accountNumber: "",
        },
      },
      options: {
        status: [],
      },
      table: {
        data: {
          HistoryProfitShare: [],
        },
      },
    };
  },
  methods: {
    periodConvertMonth(val) {
      Moment.locale("ID");
      return `${Moment(val).format("YYYY")}-${Moment(val).format("MM")}`;
    },
    async getHistoryProfitShare() {
      this.table.data.HistoryProfitShare = [];
      this.property.listElement.HistoryProfitShare.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "history-profit-share",
          payload: {
            periodDate: "",
            statusId: "",
            isTransfered: "",
            accountNumber: "",
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.HistoryProfitShare = resp.data.data;
          this.property.listElement.HistoryProfitShare.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetHistoryProfitShare(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetHistoryProfitShare(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.HistoryProfitShare.downloading = false;
      }
    },
    async searchHistoryProfitShare() {
      this.table.data.HistoryProfitShare = [];
      this.property.listElement.HistoryProfitShare.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "history-profit-share",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            statusId: this.property.filterDto.statusId,
            periodDate: this.property.filterDto.periodeDate,
            isTransfered: this.property.filterDto.isTransfered.toString(),
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.HistoryProfitShare = resp.data.data;
          this.property.listElement.HistoryProfitShare.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetHistoryProfitShare(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetHistoryProfitShare(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.HistoryProfitShare.downloading = false;
      }
    },
    failedGetHistoryProfitShare(resp) {
      this.table.data.HistoryProfitShare = [];
      this.property.listElement.HistoryProfitShare.rows = 0;
      this.property.listElement.HistoryProfitShare.message = resp.data.message;
    },

    handleErrorGetHistoryProfitShare(error) {
      console.log(error.response);
      this.table.data.HistoryProfitShare = [];
      this.property.listElement.HistoryProfitShare.rows = 0;
      this.property.listElement.HistoryProfitShare.currentPage = 1;
      this.property.listElement.HistoryProfitShare.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    routeToDetailHistoryProfitShare(props) {
      const payload = JSON.stringify(props.row);
      this.$router.push(`/history-profit-share/${this.encryptBASE64(payload)}`);
    },
    async authorizationHistoryProfitShare(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "history-profit-share/authorized/" +
                  props.row.profitShareHistoryId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.periodeDate = "";
                  this.property.filterDto.isTransfered = [];
                  this.property.filterDto.accountNumber = "";
                  this.property.filterDto.statusId = "";
                  this.getHistoryProfitShare();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async getReferenceStatus() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status/history-profit-share",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const text = i.statusName;
            const value = i.statusId;
            this.options.status.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.getReferenceStatus();
    this.getHistoryProfitShare();
  },
};
